<template>
  <div>
    <p class="font-title margin">供应商店铺</p>

    <p class="margin"><a-input-search style="width: 500px" v-model="form.search" @search="form.pageNum=1;getList()" enter-button placeholder="请输入店铺名称、证件号"></a-input-search></p>

    <a-table :data-source="data" :columns="storeColumns" :loading="loading" :rowKey="(record,index)=>{return index}" @change="handleTableChange" size="middle"
    :pagination="{
      size: 'default',
      total: total,
      current: form.pageNum,
      pageSize: form.pageSize,
      showSizeChanger: true,
      pageSizeOptions: ['5', '10', '20', '50', '100'],
      showTotal: total => `共有 ${total} 条数据`,
      showQuickJumper: true,
      onShowSizeChange: (current, pageSize) =>{form.pageSize = pageSize; form.pageNum = 1},
      onChange: (pageNum) => form.pageNum = pageNum }"
    >
    <template slot="supplierName" slot-scope="text,row">
      <router-link :to="`/shop/s${row.id}`">{{text}}</router-link>
    </template>
    <template slot="remainingSaleAmount" slot-scope="text">
      <span class="font-error">{{text}}</span>
    </template>
    <template slot="action" slot-scope="text,record">
      <a-popconfirm v-if="record.supplierType !== '个人卖家'" cancelText="取消" okText="确定" title="是否下载经营证明?" @confirm="() => downLoad(record)">
        <a>经营证明</a>
      </a-popconfirm>
    </template>
    </a-table>
  </div>
</template>

<script>
const storeColumns = [
  {
    title: '主体名称',
    dataIndex: 'supplierName',
    scopedSlots: { customRender: 'supplierName' },
    align: 'left',
  },
  {
    title: '类型',
    dataIndex: 'supplierType',
    align: 'left',
  },
  {
    title: '证照号',
    dataIndex: 'unifiedCode',
    align: 'left',
  },
  {
    title: '开户行名称',
    dataIndex: 'openBankName',
    align: 'left',
  },
  {
    title: '银行账号',
    dataIndex: 'bankAccount',
    align: 'left',
  },
  {
    title: '剩余额度(元)',
    dataIndex: 'remainingSaleAmount',
    align: 'left',
    scopedSlots: { customRender: 'remainingSaleAmount' }
  },
  {
    title: '操作',
    width: 120,
    scopedSlots: { customRender: 'action' }
  },
]
import {storeList} from '@/api/user'
export default {
    data() {
        return {
            storeColumns,
            data: [],
            loading: false,
            form: {
              pageNum: 1,
              pageSize: 5,
              search: '',
            },
            total: 0
        }
    },
    mounted() {
      this.getList()
    },
    methods: {
      getList() {
        this.loading = true
        this.form
        storeList(this.form).then(res => {
          console.log(res)
          this.loading = false
          this.data = res.data.list
          this.total = res.data.total
        })
      },
      handleTableChange (e) {
        this.form.pageNum = e.current
        this.form.pageSize = e.pageSize
        this.getList()
      },
      downLoad(row) {
        window.location.href = `${process.env.VUE_APP_API_BASE_URL}/account/download/prove?regionId=${row.regionId}&individualApplyId=${row.individualApplyId}&supplierId=${row.id}`
      }
    }
}
</script>

<style lang="less" scoped>
.font-title {
  font-weight: 650;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
  margin-bottom: 24px;
}
.margin {
    margin-bottom: 20px;
}
.font-error {
    color: rgba(217, 0, 27, 1);
    font-weight: 700;
}
</style>